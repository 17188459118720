<template>
  <section id="voordelenColor" class="pb-20">
    <div class="grid grid-cols-1 flex items-center sm:grid-cols-2 px-8 sm:px-0">
      <div class="flex-col">
        <h1 class="text-4xl text-left"><strong class="text-[#D6FF01]">Voordelen </strong>van Visionary Partners: Maak
          jouw website uniek!</h1>
        <p class="text-xl text-left mt-8 mb-8">Bij Visionary Partners bieden we een reeks voordelen die jouw online
          aanwezigheid naar een hoger niveau tillen. Ontdek hoe onze expertise en diensten jou kunnen helpen:</p>
      </div>
      <div class="flex">
        <img src="@/assets/img/pexels-fauxels-31844181.png" class="rounded-lg shadow ml-auto w-[600px]">
      </div>
    </div>

    <div class="mx-auto mt-3 md:mt-28">
      <div class="grid grid-cols-1 sm:grid-cols-2">
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 mx-auto sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            01
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">CMS systeem</h3>
            <p class="font-light text-lg">Met ons gebruiksvriendelijke Content Management Systeem (CMS) kun je <strong
                class="text-[#D6FF01]">op elk moment zelf
              teksten aanpassen.</strong>
              Dit betekent dat je de <strong class="text-[#D6FF01]">volledige controle </strong> hebt over de inhoud
              van
              je
              website, waardoor het <strong class="text-[#D6FF01]">snel en eenvoudig</strong>
              is om je site een nieuwe stijl te geven.</p>
          </div>
        </div>
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 sm:ml-10 mx-auto sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            02
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">Maatwerk</h3>
            <p class="font-light text-lg">Jouw bedrijf is uniek, en jouw website moet dat ook zijn. Wij nemen maatwerk
              serieus
              en <strong
                  class="text-[#D6FF01]">streven ernaar om samen met jou een uniek online product te creëren </strong>
              dat de essentie van jouw bedrijf weerspiegelt.</p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2">
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 mx-auto  sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            03
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">SEO optimalisatie</h3>
            <p class="font-light text-lg">Onze SEO-experts zorgen ervoor dat jouw website <strong
                class="text-[#D6FF01]">hoog in de zoekresultaten van Google verschijnt.</strong>
              Dankzij zoekmachineoptimalisatie (SEO) vergroten we de zichtbaarheid van je website en trekken we meer
              potentiële klanten aan.</p>
          </div>
        </div>
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 sm:ml-10 mx-auto sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            04
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">Gezamenlijke ideeën</h3>
            <p class="font-light text-lg">Onze ervaren teamleden begeleiden je bij het verkennen van unieke en creatieve
              ideeën
              voor je website of app. We luisteren naar al je eisen en wensen en combineren deze met onze professionele
              inzichten voor een <strong class="text-[#D6FF01]">succesvol eindresultaat</strong></p>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2">
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 mx-auto sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            05
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">Website onderhoud</h3>
            <p class="font-light text-lg">Met Visionary Partners kun je jouw <strong
                class="text-[#D6FF01]">website onderhouden </strong>
              in onze <strong class="text-[#D6FF01]">beveiligde cloudomgeving.</strong> Wij garanderen een uptime van
              99,9% en zorgen ervoor dat je site altijd up-to-date is met de nieuwste versies en beveiligingspatches.
            </p>
          </div>
        </div>
        <div class="text-left grid grid-cols-12 p-8 sm:p-0 mb-5 sm:ml-10 mx-auto sm:mx-0">
          <div class="float-left text-4xl col-span-2">
            06
          </div>
          <div class="col-span-10 lg:w-[425px]">
            <h3 class="text-4xl mb-2">Punctuele oplevering</h3>
            <p class="font-light text-lg">We beloven dat jouw website op tijd wordt opgeleverd, zoals afgesproken.
              <strong
                  class="text-[#D6FF01]">We regelen alles </strong>
              van tevoren, inclusief certificaten, domeinnamen en hosting, zodat je geen tijd hoeft te verspillen aan
              administratieve zaken.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Voordelen"
}
</script>

<style scoped>

</style>