<template>
  <section id="waaromColor" class="pb-20 pt-10">
    <div class="grid grid-cols-1 flex items-center sm:grid-cols-2 px-8 sm:px-0">
      <div class="flex-col">
        <h1 class="text-4xl text-left"><strong class="text-[#D6FF01]">Waarom </strong>kiezen voor visionary partners?
        </h1>
        <p class="text-xl text-left mt-8 mb-8">Wij hebben gespecialiseerde programmeurs die zich verdiept hebben in het
          sneller
          maken van de website. Hierdoor scoort jouw website ook beter in de zoekmachines.</p>
      </div>
      <div class="flex">
        <img src="@/assets/img/pexels-lukas-5740711.png" class="rounded-lg shadow ml-auto w-[600px]">
      </div>
    </div>
    <section id="pakketten" />
  </section>
</template>

<script>
export default {
  name: "Waarom"
}
</script>

<style scoped>

</style>